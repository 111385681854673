@import "../../constants.scss";

.title {
  font-weight: 600;
  margin-right: 8px;
}

.name {
  margin-top: 8px;
}

.labelValue {
  margin-right: 24px;
  display: inline-block;
}

.editButton {
  border: 1px solid $theme-navy;
  margin-right: 8px;
}

.deleteButton {
  border: 1px solid $theme-orange-dark;
  color: rgb(228, 0, 0);
}

.buttonIcon {
  margin-right: 4px;
}
