.checkbox {
  padding-bottom: 3px;
  padding-top: 3px;
}

.container {
  background-color: white;
  border: 1px solid #dadada;
  margin-bottom: 0.25rem;
  padding-left: 0.25rem;
  &:hover {
    background-color: #dadada;
  }

  &:focus {
    // Safari hides outline if parent has offset hidden
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: #000033;
  }
}
