.itemContainer {
  background-color: white;
  border: 1px solid #dadada;
  cursor: pointer;
  margin-bottom: 8px;
  line-height: 56px;
  //   height: 40px;
  position: relative;
  // This is needed as safari cuts off the items for being slightly too big
  width: calc(100% - 1px);

  &:hover {
    background-color: #dadada;
  }

  &:focus {
    // Safari hides outline if parent has offset hidden
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: #000033;
  }
}
