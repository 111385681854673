.container {
  width: 100%;
  font-style: italic;
  margin-top: 0.5ex;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: baseline;
}

.rSquared {
  margin-left: 1em;
}
