@import "../../constants.scss";

.infoIcon {
  color: $info-gray;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 24px;
  max-width: 90vw;
  outline: none;
}

.headerCell {
  font-weight: 700;
  font-size: large;
}

.methodCell {
  font-weight: 600;
}
