@import "../../constants";

.nav {
  height: $navbar-height;
  background-color: white;
  box-shadow: none;
  overflow: visible;
  border-radius: 0px;
  border-bottom: $border-thickness solid $theme_navy;
  :global(.MuiToolbar-root) {
    flex: 1;
  }
}

.menuItem {
  font-size: 1.1rem;

  color: $theme_navy;
  height: $navbar-height;
  margin: 0 1rem 0 1rem;
  border-radius: 0px;
}

.active {
  border-bottom: 0.4rem solid $theme_navy;
}

.link {
  color: inherit;
  text-decoration: none;

  &:global(.active) {
    text-decoration: underline;
  }
}

.logoWrapper {
  margin-right: 25px;
  display: flex;
  align-items: flex-start;
  background: $theme-gold;
  align-content: center;
  height: $navbar-height;
  width: $navbar-height;
  border-bottom: $border-thickness solid $theme_navy;
}

.logo {
  margin: auto;
  max-height: $navbar-height - 10;
  max-width: $navbar-height - 10;
}

.email {
  color: $theme-navy;
  font-style: italic;
  width: 3em;
  flex-grow: 1;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}
