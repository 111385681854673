.itemContainer {
  background-color: white;
  border: 1px solid #dadada;
  cursor: pointer;
  display: grid;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 8px;
  min-width: 50vw;
  position: relative;
  // This is needed as safari cuts off the items for being slightly too big
  width: calc(100% - 1px);

  &:hover {
    background-color: #dadada;
  }

  &:focus {
    // Safari hides outline if parent has offset hidden
    outline-offset: -1px;
    outline-width: 1px;
    outline-color: #000033;
  }
}

.field {
  padding: 8px;
  margin-right: 16px;
}

.fieldHeader {
  font-weight: 700;
  font-size: 0.7rem;
  margin-bottom: 4px;
  color: #818181;
  text-transform: uppercase;
}
