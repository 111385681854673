$border-color: #dedede;

.table {
  border-collapse: collapse;
  border: 1px solid $border-color;
  width: 100%;
  table-layout: fixed;
}

.headerCell {
  border: 1px solid $border-color;
  background-color: #ebebeb;
  padding: 8px;
  text-align: left;
  align-items: center;
}

.headerCellContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sortable {
  cursor: pointer;
}

.cell {
  border: 1px solid $border-color;
  background-color: #f7f7f7;
  padding: 8px;
  text-align: left;
}

.editable {
  background-color: #fefefe;
}
