@import "../../constants.scss";

.container {
  padding: 1rem;
  border-radius: 0.2rem;
  border: $border-thickness solid $theme_navy;
  margin: 2rem;
  background-color: white;
  position: relative;

  > label {
    position: absolute;
    top: -1rem;
    left: 1rem;
    padding-left: 0.2em;
    padding-right: 0.2em;
    background-color: white;
    font-size: 1.1rem;
    color: $theme_navy;
  }
}
