@import "../../constants";

.plot {
  padding: 16px;
  border-bottom: 2px solid rgb(177, 177, 177);
}

.left {
  border-right: 2px solid rgb(177, 177, 177);
}

.bottom {
  border-bottom: none;
}

.hidden {
  height: 0;
  overflow: hidden;
  z-index: -1;
}

.selected {
  background: $theme-gold;
}

.tab {
  font-size: medium;
  color: $theme-navy;
}

.container {
  background-color: #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
}

.divider {
  border: 1px solid $theme-navy;
  margin: 3rem 2rem;
}

.graphView {
  border: 1px solid rgb(177, 177, 177);
  border-radius: 5px;
  overflow: hidden;
  margin: 2em 0 1em 0;
}

.graphContainer {
  padding: 0 1em 1em 1em;
}

.tabsContainer {
  height: 60px;
}

.methodSelects {
  display: flex;
  align-items: center;
}

.dataTable {
  margin-bottom: 24px;
}

.comparisonSelect {
  min-width: 5em;
  padding: 10px 14px;
}
