/**
 * Constants
 *
 */

// AIS Brand Colours
$theme-navy: #000033;
$theme-gold: #ffc709;
$theme-yellow: #ffdd00;
$theme-green: #9ecf7c;
$theme-green-dark: #2e9e46;
$theme-orange: #f9a456;
$theme-orange-dark: #df6420;
$theme-blue: #64cbe8;

// Extra Colours
$white: #ffffff;
$black: #000000;
$gray-100: #f1f1f1;
$gray-200: #eaeaea;
$gray-300: #a7a7a7;
$gray-900: #46464c;
// Some element Sizing options...

$border-thickness: 0.1rem;
$navbar-height: 65px;

$info-gray: #777777;
