.removeButton {
  padding: unset;
  min-width: unset;
}

.headerBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.editStepsContainer {
  display: flex;
  flex-direction: row;
}

.tableContainer {
  flex-grow: 1;
  margin-right: 4px;
}

.addButton {
  border-radius: 2px;
  border: 1px solid #ddd;
}

.columnHeaderText {
  overflow: hidden;
  text-overflow: ellipsis;
}
