.box {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

.card {
  padding: 3ex;
}

.card-header {
  text-align: center;
}
