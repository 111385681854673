@import "../../constants.scss";

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  margin-right: 8px;
}

.infoIcon {
  color: $info-gray;
}

.infoText {
  font-size: 1.3em;
}
